<template>
  <v-form
    v-bind="$attrs"
    ref="form"
    v-model="valid"
    lazy-validation
    @submit="onSubmit"
  >
    <HelpFormInputVue
      dark
      v-model="form.name"
      label="Folder Name"
      :rules="nameRules"
      :helpText="'Please provide a name for the new folder'"
      required
    ></HelpFormInputVue>
  </v-form>
</template>
  
  
  <script>
import HelpFormInputVue from "../../../../../../components/wad/atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    console.log("This value: ", this.value);

    return {
      valid: true,
      form: this.value,

      // ======rules
      nameRules: [(v) => !!v || "Folder Name is required"],
    };
  },
  components: {
    HelpFormInputVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onSubmit(e){
      e.preventDefault();
      this.$emit('onSubmit')
    }
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
};
</script>